/* eslint-disable camelcase */
import yaml from 'js-yaml';
import { merge, isErrorResult } from 'openapi-merge';
import localSpecs from '../assets/spec.yaml';
import { API_ENDPOINTS } from '../constants/endpoints';

/**
 * Fetches and returns API schemas from local storage or remote endpoints.
 *
 * This function first checks if the schemas are available in the local storage
 * and if they are not older than 24 hours. If valid schemas are found, they are
 * returned immediately. Otherwise, it fetches the schemas from three remote
 * endpoints: License Manager, Enterprise Access, and Enterprise Catalog.
 * The fetched schemas are then parsed from YAML format, stored in local storage
 * with a timestamp, and returned.
 *
 * @returns {Promise<Object>} A promise that resolves to an object containing
 * the fetched schemas, or an empty object if an error occurs.
 */
export const fetchSchemas = async () => {
  const localStorageKey = 'apiSchemas';
  const storedData = localStorage.getItem(localStorageKey);
  const currentTime = new Date().getTime();

  // eslint-disable-next-line no-console
  console.log('Started fetching schemas.');
  if (storedData) {
    const parsedData = JSON.parse(storedData);
    if (currentTime - parsedData.timestamp < 24 * 60 * 60 * 1000) {
      return parsedData.schemas;
    }
  }
  try {
    const [lmResponse, enterpriseAccessResponse, catalogResponse] = await Promise.all([
      fetch(`${process.env.LICENSE_MANAGER_URL}/api/schema/`),
      fetch(`${process.env.ENTERPRISE_ACCESS_URL}/api/schema/`),
      fetch(`${process.env.ENTERPRISE_CATALOG_URL}/api/schema/`),
    ]);
    const [lm, enterpriseAccess, catalog] = await Promise.all([
      lmResponse.text(),
      enterpriseAccessResponse.text(),
      catalogResponse.text(),
    ]);

    const schemas = {
      lm: yaml.load(lm),
      enterpriseAccess: yaml.load(enterpriseAccess),
      catalog: yaml.load(catalog),
    };

    localStorage.setItem(
      localStorageKey,
      JSON.stringify({ schemas, timestamp: currentTime }),
    );

    return schemas;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching schemas:', error);
    return {};
  }
};

/**
 * Merges the YAML files fetched from different sources and retains only the endpoints
 * required for this MFE. It also modifies the paths
 * of the schemas to ensure uniqueness and updates the tags and methods of the endpoints
 * based on predefined API endpoints.
 *
 * @param {Object} schemas - The schemas fetched from various sources.
 * @returns {Object} The merged schema with filtered and updated endpoints.
 */
export const mergeSchemas = (schemas) => {
  // eslint-disable-next-line no-console
  console.log('Started merging schemas:', schemas);

  const mergeResult = merge([
    {
      oas: localSpecs,
    },
    {
      oas: schemas.enterpriseAccess,
      pathModification: {
        prepend: 'enterpriseaccess::', // workaround to fix duplicate paths
      },
    },
    {
      oas: schemas.lm,
      pathModification: {
        prepend: 'licensemanager::',
      },
    },
    {
      oas: schemas.catalog,
      pathModification: {
        prepend: 'catalog::',
      },
    },
  ]);

  if (isErrorResult(mergeResult)) {
    // eslint-disable-next-line no-console
    console.error(
      `Error in merging: ${mergeResult.message} (${mergeResult.type})`,
    );
  } else {
    const updatedPaths = {};

    // eslint-disable-next-line object-curly-newline
    try {
      API_ENDPOINTS.forEach(
        ({
          original_link,
          new_link,
          tag,
          summary,
          method,
        }) => {
          const pathItem = mergeResult.output.paths[original_link];

          if (!pathItem) {
            return;
          }

          if (method) {
            method.forEach((methodType) => {
              if (pathItem[methodType]?.tags) {
                pathItem[methodType].tags = [tag];
                if (summary) {
                  pathItem[methodType].summary = summary;
                }
              }
            });

            Object.keys(pathItem).forEach((existingMethod) => {
              if (!method.includes(existingMethod)) {
                delete pathItem[existingMethod];
              }
            });
          }

          updatedPaths[new_link] = pathItem;
        },
      );
      mergeResult.output.paths = updatedPaths;
    } catch {
      // eslint-disable-next-line no-console
      console.log('Error occurred while modifying schema');
    }
  }
  return mergeResult;
};
