import { useEffect, useState } from 'react';
import { RedocStandalone } from 'redoc';
import { fetchSchemas, mergeSchemas } from '../utils/swagger';

const RedocComponent = () => {
  const [specs, setSpecs] = useState();
  useEffect(() => {
    fetchSchemas().then((schemas) => {
      const mergedSchemas = mergeSchemas(schemas);
      setSpecs(mergedSchemas);
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error fetching schemas:', error);
    });
  }, []);

  if (!specs) {
    return 'Loading...';
  }
  return (
    <div style={{ width: '100%' }}>
      <RedocStandalone
        spec={specs.output}
        options={{
          scrollYOffset: 50,
          theme: { colors: { primary: { main: '#0A3055' } } },
        }}
      />
    </div>
  );
};

export default RedocComponent;
