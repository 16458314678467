/* eslint-disable import/prefer-default-export */
const API_ENDPOINTS = [
  // Access/Learner Credit
  {
    original_link: '/oauth2/v1/access_token',
    new_link: '/oauth2/v1/access_token',
    tag: 'Authentication',
    method: ['post'],
  },
  {
    original_link:
      'enterpriseaccess::/api/v1/policy-allocation/{policy_uuid}/allocate/',
    new_link: '/enterprise/v1/policy-allocation/{policy_uuid}/allocate/',
    tag: 'Access/Learner Credit',
    method: ['post'],
  },
  {
    original_link: 'enterpriseaccess::/api/v1/subsidy-access-policies/',
    new_link: '/enterprise/v1/subsidy-access-policies/',
    tag: 'Access/Learner Credit',
    method: ['get'],
  },
  {
    original_link:
      'enterpriseaccess::/api/v1/assignment-configurations/{assignment_configuration_uuid}/admin/assignments/',
    new_link:
      '/enterprise/v1/assignment-configurations/{assignment_configuration_uuid}/admin/assignments',
    tag: 'Access/Learner Credit',
    method: ['get'],
  },
  {
    original_link:
      'enterpriseaccess::/api/v1/assignment-configurations/{assignment_configuration_uuid}/admin/assignments/remind/',
    new_link:
      '/enterprise/v1/assignment-configurations/{assignment_configuration_uuid}/admin/assignments/remind/',
    tag: 'Access/Learner Credit',
    method: ['post'],
    summary: 'Send reminders to a list of learners',
  },
  {
    original_link:
      'enterpriseaccess::/api/v1/assignment-configurations/{assignment_configuration_uuid}/admin/assignments/cancel/',
    new_link:
      '/enterprise/v1/assignment-configurations/{assignment_configuration_uuid}/admin/assignments/cancel/',
    tag: 'Access/Learner Credit',
    method: ['post'],
    summary: 'Cancel list of LearnerContentAssignment records by uuid',
  },
  // Access/Subscriptions
  {
    original_link: 'licensemanager::/api/v1/{var}subscriptions/',
    new_link: '/enterprise/v1/subscriptions/',
    tag: 'Access/Subscriptions',
    method: ['get'],
  },
  {
    original_link:
      'licensemanager::/api/v1/{var}subscriptions/{subscription_uuid}/licenses/assign/',
    new_link: '/enterprise/v1/subscriptions/{uuid}/licenses/assign',
    tag: 'Access/Subscriptions',
    method: ['post'],
    summary: 'Assign a license to user emails',
  },
  {
    original_link:
      'licensemanager::/api/v1/{var}subscriptions/{subscription_uuid}/licenses/bulk-revoke/',
    new_link: '/enterprise/v1/subscriptions/{uuid}/licenses/bulk-revoke',
    tag: 'Access/Subscriptions',
    method: ['post'],
    summary: 'Bulk license revoke',
  },
  {
    original_link: 'licensemanager::/api/v1/bulk-license-enrollment',
    new_link: '/enterprise/v1/bulk-license-enrollment',
    tag: 'Access/Subscriptions',
    method: ['post'],
    summary: 'Bulk license enrollment',
  },
  // Discovery
  {
    original_link: 'catalog::/api/v2/enterprise-catalogs/',
    new_link: '/enterprise/v2/enterprise-catalogs',
    tag: 'Discovery',
    method: ['get'],
    summary: 'Get a list of enterprise catalogs',
  },
  {
    original_link: 'catalog::/api/v2/enterprise-catalogs/{uuid}/',
    new_link: '/enterprise/v2/enterprise-catalogs/{uuid}',
    tag: 'Discovery',
    method: ['get'],
    summary: 'Get an enterprise catalog by UUID',
  },
  {
    original_link:
      '/enterprise/v2/enterprise-catalogs/{uuid}/course-runs/{course_run_id}',
    new_link:
      '/enterprise/v2/enterprise-catalogs/{uuid}/course-runs/{course_run_id}',
    tag: 'Discovery',
    method: ['get'],
  },
  {
    original_link:
      '/enterprise/v2/enterprise-catalogs/{uuid}/courses/{course_key}',
    new_link: '/enterprise/v2/enterprise-catalogs/{uuid}/courses/{course_key}',
    tag: 'Discovery',
    method: ['get'],
  },
  {
    original_link:
      '/enterprise/v2/enterprise-catalogs/{uuid}/programs/{program_uuid}',
    new_link:
      '/enterprise/v2/enterprise-catalogs/{uuid}/programs/{program_uuid}',
    tag: 'Discovery',
    method: ['get'],
  },
  // Reporting
  {
    original_link:
      '/enterprise/v3/enterprise-customer/{enterprise_uuid}/learner-summary',
    new_link:
      '/enterprise/v3/enterprise-customer/{enterprise_uuid}/learner-summary',
    tag: 'Reporting',
    method: ['get'],
  },
  {
    original_link: '/{Xapi-customer-channel-url}/',
    new_link: '/{Xapi-customer-channel-url}/',
    tag: 'Reporting',
    method: ['post'],
  },
  {
    original_link: '/{Xapi-customer-channel-url-learner-course-completion}/',
    new_link: '/{Xapi-customer-channel-url-learner-course-completion}/',
    tag: 'Reporting',
    method: ['post'],
  },
];

export { API_ENDPOINTS };
